import Rails from "rails-ujs";
import JSTZ from "jstz";
import 'whatwg-fetch';

import TimeoutPromise from "./timeout_promise";
import FetchStatusError from "./fetch_status_error";

//this function will work cross-browser for loading scripts asynchronously
function loadScript(src, callback) {
  let s,
      r,
      t;
  r = false;
  s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = src;
  s.onload = s.onreadystatechange = function() {
    if ( !r && (!this.readyState || this.readyState == 'complete') )
    {
      r = true;
      callback();
    }
  };
  t = document.querySelector('script');
  t.parentNode.insertBefore(s, t);
}

function fetchJSON(url, options = {}) {
  return new Promise((resolve, reject) => {
    options.headers = options.headers || {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    };
    if (options.data) {
      options.body = JSON.stringify(options.data);
    }
    fetch(url, options)
      .then(res => {
        if (!res.ok) {
          // Return parsed json error response or generic error
          return res.json()
            .then(json => reject(json))
            .catch(() => reject(new FetchStatusError(`Fetch response not ok. Status: ${res.status}`, res)))
        }
        if (res.redirected) {
          window.location = res.url;
          return reject(`Redirecting to ${res.url}`);
        }

        // Return parsed json response or {} if no response body
        return resolve(res.text().then(text => {
          return text ? JSON.parse(text) : {};
        }))
      })
      .catch(e => reject(e))
  })
}

function fetchHTML(url, options = {}) {
  return new Promise((resolve, reject) => {
    options.headers = options.headers || {
      'Accept': 'text/html',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    }
    if (options.data) {
      options.body = JSON.stringify(options.data);
    }
    fetch(url, options)
      .then(res => {
        if (!res.ok) {
          return reject(new FetchStatusError(`Fetch response not ok. Status: ${res.status}`, res));
        }
        if (res.redirected) {
          window.location = res.url;
          return reject(`Redirecting to ${res.url}`);
        }

        return resolve(res.text());
      })
      .catch(e => reject(e))
  })
}

// Return raw fetch response if successful
function fetchBASIC(url, options = {}) {
  return new Promise((resolve, reject) => {
    options.headers = options.headers || {
      'Accept': 'text/html',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    }
    if (options.data) {
      options.body = JSON.stringify(options.data);
    }
    fetch(url, options)
      .then(res => {
        if (res.redirected) {
          window.location = res.url;
          return reject(`Redirecting to ${res.url}`);
        }
        if (!res.ok) {
          if (process.env.NODE_ENV === "development"){
            alert( "That did not float! Check Console.log" );
            console.log(res);
          }
          return reject(new FetchStatusError(`Fetch response not ok. Status: ${res.status}`, res));
        }
        return resolve(res);
      })
      .catch(e => reject(e))
  })
}

// Allows retrying fetchJSON based on a retryCondition function
// url: Url to fetch
// retryCondition: callback function used to check if retry is needed.
//                 Function will be called with the json response or fetch error.
// fetchOptions: options that are passed along to fetch
// retriesAllowed: maximum number of retries allowed
// delay: Number of milliseconds between retry attempts
function fetchJSONWithRetry(url, retryCondition, fetchOptions = {}, retriesAllowed = 5, delay = 2000) {
  return new Promise((resolve, reject) => {
    let currentRetries = 0;

    const retryFn = data => {
      if (!retryCondition(data)) {
        return resolve(data);
      }

      currentRetries++;

      if (currentRetries > retriesAllowed) {
        reject(data);
      } else {
        new TimeoutPromise()
          .timeout(delay)
          .then(() => {
            makeCall();
          });
      }
    }

    const makeCall = () => {
      fetchJSON(url, fetchOptions)
        .then(data => retryFn(data))
        .catch(e => retryFn(e))
    };

    makeCall();
  });
}

function hideOnClickOutside(element, className, action, targetElement = element) {
  const outsideClickListener = event => {
    if (!element.contains(event.target)) {
      if (action === "remove") {
        targetElement.classList.remove(className);
      } else if (action === "add") {
        targetElement.classList.add(className);
      }

      removeClickListener()
    }
  }
  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }
  document.addEventListener('click', outsideClickListener)
}

function findTimeZone() {
  const oldIntl = window.Intl;
  try {
    window.Intl = undefined;
    const tz = JSTZ.determine().name();
    window.Intl = oldIntl
    return tz
  } catch (e) {
    // sometimes (on android) you can't override intl
    return JSTZ.determine().name()
  }
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  const expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export {
  loadScript,
  fetchJSON,
  fetchHTML,
  fetchBASIC,
  fetchJSONWithRetry,
  hideOnClickOutside,
  findTimeZone,
  setCookie,
  getCookie
}
